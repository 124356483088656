import React from "react";
import { Link, NavLink } from "react-router-dom";
import { clearLocalStorage, getLocalStorage } from "../utils/storageUtil";
import { JWT_TOKEN } from "../constants";
import logo from "../assets/images/ico/logo-633x179.png";
const Header = () => {
  let token = getLocalStorage(JWT_TOKEN);

  return (
    <>
      <section id="header">
        <div className="inner">
          {/* <h1> */}
          <Link to="/">
            <img src={logo} height={50} alt="DAO sms" />
          </Link>
          {/* </h1> */}
          <ul className="menu_list">
            <li>
              <NavLink
                to="/message/create"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                메시지 발송
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/credit/list"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                크레딧
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
                to="/notification/list"
              >
                공지사항
              </NavLink>
            </li>
            <li>
              <a href="https://t.me/momo_sms" target="_blank">
                고객센터
              </a>
            </li>
          </ul>
          <div className="right_box">
            <div className="lang_box">
              <button>KOR</button>
              <ul className="lang_list">
                <li className="active">
                  <a href="#">KOR</a>
                </li>
                <li>
                  <a href="#">ENG</a>
                </li>
              </ul>
            </div>
            {token ? (
              <div className="profile_box">
                <button>
                  <img
                    src="https://static.vecteezy.com/system/resources/previews/019/879/186/non_2x/user-icon-on-transparent-background-free-png.png"
                    className="profile-img"
                    alt="Profile"
                    height={32}
                  />
                </button>
                <ul className="profile_list profile-btn">
                  <li className="active">
                    <Link to="/profile">프로필</Link>
                  </li>
                  <li>
                    <p
                      onClick={() => {
                        clearLocalStorage(JWT_TOKEN);
                      }}
                      style={{fontSize:"1.6rem",color:"#18171b",fontWeight:"500",cursor:"pointer"}}
                    >
                      로그아웃
                    </p>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="btn_wrap">
                <Link to="login" className="btn_stl01 white">
                  로그인
                </Link>
                <Link to="/register" className="btn_stl01 black">
                  회원가입
                </Link>
              </div>
            )}

            {/* <!-- 프로필 이미지 없을 시 --> */}
            <div className="profile_box" style={{ display: "none" }}>
              <button className="profile_img"></button>
              <ul className="profile_link">
                <li>
                  <a href="#">계정관리</a>
                </li>
                <li>
                  <a href="#">프로필</a>
                </li>
                <li className="logout">
                  <a href="#">로그아웃</a>
                </li>
              </ul>
            </div>

            {/* <!-- *************** 로그인 했을시 시작 ********************* --> */}

            {/* <!-- *************** // 로그인 했을시 끝 ********************* --> */}
            <button className="menu_open mob">
              <span></span>
            </button>
          </div>
          <div className="nav mob">
            <button className="menu_close">
              <span></span>
            </button>
            <ul className="nav_list">
              <li>
                <NavLink
                  to="/message/create"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  메시지 발송
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/credit/list"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  크레딧
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  to="/notification/list"
                >
                  공지사항
                </NavLink>
              </li>
              <li>
                <a href="https://t.me/momo_sms" target="_blank">
                  고객센터
                </a>
              </li>
            </ul>
            <div className="auth_link">
              <Link to="login" className="btn_stl01 white">
                로그인
              </Link>
              <Link to="/register" className="btn_stl01 black">
                회원가입
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
