import { useEffect, useState } from "react";
import Header from "../common/Header";
import { Outlet, useLocation } from "react-router-dom";
const WebLayout = () => {
  const [isSticky, setIsSticky] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      offset > 10 ? setIsSticky(true) : setIsSticky(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {pathname != "/login" && pathname != "/register" && (
        <div>
          <Header />
        </div>
      )}
      <main>
        <Outlet />
      </main>
      {/* <div>
        {" "}
        <Footer />
      </div> */}
    </>
  );
};

export default WebLayout;
