import { Route, Routes } from "react-router-dom";
import "./App.css";
import Webroutes from "./routes/WebRoutes";
import { Suspense, useEffect, useState } from "react";
import WebLayout from "./layout/WebLayout";
import ProtectedComponent from "./common/ProtectedComponent";
import Loader from "./common/Loader";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration (default: 400ms)
      easing: "ease-in-out", // Easing type
      once: false, // Whether animation should happen only once
      offset: 100, // Offset from the original trigger point
    });
  }, []);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Routes>
        <Route element={<WebLayout />}>
          {Webroutes.map((route, index) => {
            const {
              path,
              component: Component,
              protected: isProtected,
            } = route;

            return (
              <Route
                key={index}
                path={path}
                element={
                  <Suspense fallback={<Loader />}>
                    {isProtected ? (
                      <ProtectedComponent>
                        <Component />
                      </ProtectedComponent>
                    ) : (
                      <Component />
                    )}
                  </Suspense>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </>
  );
}

export default App;
