export const JWT_TOKEN = "dao-auth@$#";
export const USER_UUID = "dao-auth@$#-uuid";
export const IMG_URL = "mx-7D-IMG";
export const USER_DETAILS = "user_details";

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
export const IMAGE_SUPPORTED_FORMATS = ["image/jpeg", "image/png", "image/jpg"];

export const UserApiEndPoint = {
  signup: "users/user-register",
  signin: "users/user-login",
  accountDelete: "users/account-delete",
  getUserDetails: "users/user-details",
  imageUpload: "users/image-upload",
  userEmailVerify: "users/user-verify",
  resetPassword: "users/reset-password",
  directInput: "users/direct-input",
  directInput: "users/account-delete",
};

export const CampaignApiEndPoint = {
  directInput: "campaign/direct-input",
  deleteDirectInput: "campaign/delete-direct-input",
  deleteAllDirectIput: "campaign/delete-all-direct-input",
  getAllDirectIput: "campaign/get-all",
  uploadContacts: "campaign/upload-contacts",
};

export const AnnouncementApiEndPoint = {
  create: "announcement/create",
  update: "announcement/update",
  delete: "announcement/delete",
  getAll: "announcement/get-all",
  getAnnouncementById: "announcement/get-announcement",
};

export const getTimeAgo = (dateString) => {
  const now = new Date();
  const createdAtDate = new Date(dateString);
  const diffInMs = now - createdAtDate;

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (weeks >= 1) {
    return `${weeks} Week Ago`;
  } else if (days >= 1) {
    return `${days} Days Ago`;
  } else if (hours >= 1) {
    return `${hours} Hours Ago`;
  } else if (minutes >= 1) {
    return `${minutes} Min Ago`;
  } else {
    return `${seconds} Sec Ago`;
  }
};
