import { lazy } from "react";
const HomePage = lazy(() => import("../pages/HomePage"));
const Login = lazy(() => import("../pages/Auth/Login"));
const Register = lazy(() => import("../pages/Auth/Register"));
const forgotPassword = lazy(() => import("../pages/Auth/FindPassword"));
const ResetPassword = lazy(() => import("../pages/Auth/ResetPassword"));
const ThirdPartyTest = lazy(() => import("../pages/Messages/ThirdPartyTest"));
const CreateMessage = lazy(() => import("../pages/Messages/CreateMessage"));
const MessageHistory = lazy(() => import("../pages/Messages/MessageHistory"));
const CreditList = lazy(() => import("../pages/credits/CreditList"));
const CreditHistory = lazy(() => import("../pages/credits/CreditHistory"));
const CreditPayment = lazy(() => import("../pages/credits/CreditPayment"));
const CreditComplete = lazy(() => import("../pages/credits/CreditComplete"));
const NotificationList = lazy(() => import("../pages/NotificationList"));
const NotificationView = lazy(() => import("../pages/NotificationView"));
const Profile = lazy(() => import("../pages/Auth/Profile"));

const coreRoutes = [
  {
    path: "/",
    title: "Home",
    component: HomePage,
    protected: false,
  },
  {
    path: "/login",
    title: "Login",
    component: Login,
    protected: false,
  },
  {
    path: "/register",
    title: "Register",
    component: Register,
    protected: false,
  },
  {
    path: "/find-password",
    title: "find-password",
    component: forgotPassword,
    protected: false,
  },
  {
    path: "/users/reset-password/:uuid/:token",
    title: "find-password",
    component: ResetPassword,
    protected: false,
  },
  {
    path: "/profile",
    title: "profile",
    component: Profile,
    protected: true,
  },
  {
    path: "/create-message",
    title: "create message",
    component: CreateMessage,
    protected: true,
  },
  {
    path: "/message/create",
    title: "create message",
    component: CreateMessage,
    protected: true,
  },
  {
    path: "/message/history",
    title: "MessageHistory",
    component: MessageHistory,
    protected: true,
  },
  {
    path: "/message/test",
    title: "ThirdPartyTest",
    component: ThirdPartyTest,
    protected: true,
  },
  {
    path: "/credit/list",
    title: "CreditList",
    component: CreditList,
    protected: true,
  },
  {
    path: "/credit/history",
    title: "CreditHistory",
    component: CreditHistory,
    protected: true,
  },
  {
    path: "/credit/payment/:id",
    title: "CreditPayment",
    component: CreditPayment,
    protected: true,
  },
  {
    path: "/credit/complete",
    title: "CreditComplete",
    component: CreditComplete,
    protected: true,
  },
  {
    path: "/notification/list",
    title: "notificationList",
    component: NotificationList,
    protected: true,
  },
  {
    path: "/notification/:id/view",
    title: "notificationView",
    component: NotificationView,
    protected: true,
  },
];

const Webroutes = [...coreRoutes];
export default Webroutes;
