import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { JWT_TOKEN } from "../constants";
import { getLocalStorage } from "../utils/storageUtil";

const ProtectedComponent = ({ children }) => {
  const navigate = useNavigate();
  const token = getLocalStorage(JWT_TOKEN);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  if (!token) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

export default ProtectedComponent;
